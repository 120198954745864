import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { MassageService } from '../../massage/massage.service';

@Component({
    selector: 'app-admin-pass',
    templateUrl: './admin-pass.component.html',
    styleUrls: ['./admin-pass.component.scss'],
})
export class AdminPassComponent implements OnInit {

    public pass: BehaviorSubject<string>
    public form = {
        password: ""
    }
    changeForm = {
        oldPassword: "",
        password: "",
        confirmpass: ""
    }
    hidden: boolean = true
    hidden1: boolean = true
    hidden2: boolean = true
    changePasswordForm: boolean = false
    constructor(
        private modalController: ModalController,
        private afs: AngularFirestore,
        private _msgService: MassageService
    ) {

        afs.collection("admin").doc("password").get().subscribe((data: any) => {
            console.log(data.data().password);
            this.pass = new BehaviorSubject(data.data().password)
        })
    }

    ngOnInit() { }

    password() {
        if (this.form.password != this.pass.value) {
            this._msgService.presentToast("You have entered an invalid password", "danger")
            return
        }
        this.modalController.dismiss({ isAdmin: (this.form.password == this.pass.value) })

    }
    dissmiss() {
        this.modalController.dismiss({ isAdmin: false })
    }
    saveNewPassword() {
        if (this.pass.value != this.changeForm.oldPassword) {
            this._msgService.presentToast("Old Password not Match", "danger")
            return
        }
        if (this.changeForm.password != this.changeForm.confirmpass) {
            this._msgService.presentToast("Confirem Password not Match", "danger")
            return
        }
        this.afs.collection("admin").doc("password").set({ password: this.changeForm.password })
        this._msgService.presentToast("Password Changed", "success")
        this.dissmiss()
    }
}
