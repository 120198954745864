import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AdminPassComponent } from './provider/guard/admin-pass/admin-pass.component';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const firebaseConfig = {
    apiKey: "AIzaSyBhwwiOhmXa8jHg9LUGulsRX7ih095AUtE",
    authDomain: "apnabazar-5dffb.firebaseapp.com",
    databaseURL: "https://apnabazar-5dffb.firebaseio.com",
    projectId: "apnabazar-5dffb",
    storageBucket: "apnabazar-5dffb.appspot.com",
    messagingSenderId: "770031543991",
    appId: "1:770031543991:web:3671becb88d6fb703d0f32",
    measurementId: "G-FXQ9PTSEKZ"
}

@NgModule({
    declarations: [AppComponent, AdminPassComponent],
    entryComponents: [],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFireAuthModule],
    providers: [
        StatusBar,
        SplashScreen,
        AngularFireAuthModule,
        AngularFireAuthGuardModule,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: BUCKET, useValue: 'gs://apnabazar-5dffb.appspot.com' },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
