import { Component } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public menu = menu
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private Auth: AngularFireAuth,
    private Router: Router,
    private menuController: MenuController
  ) {
    this.initializeApp();
  }
  spliteMenu: boolean = true
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.Router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        const teb = this.menu.findIndex(ele => ele?.routerLink == data.url)
        this.selectIndex(teb);
        if (data.url === "/login") {
          this.spliteMenu = true
          this.menuController.enable(false, 'menu-content')
        } else {
          this.spliteMenu = false
          this.menuController.enable(true, 'menu-content')
          if (!this.menuController.isOpen()) {
            this.menuController.open()
          }

        }
      })

  }
  logOut() {
    this.Auth.signOut().then(() => {
      this.Router.navigateByUrl("/login")
      this.spliteMenu = true

    })

  }
  activeIndex: number = 0
  selectIndex(index: number) {
    this.activeIndex = index
  }
}

const menu = [
  {
    name: "Home",
    routerLink: "/home",
    icon: "home-outline"
  },
  {
    name: "Notification",
    routerLink: "/notification",
    icon: "send-outline"
  },
  {
    name: "Slider",
    routerLink: "/slider",
    icon: "albums-outline"
  },
  {
    name: "Manage User",
    routerLink: "/manage-users",
    icon: "people-outline"
  },
  {
    name: "Manage Arrival Price",
    routerLink: "/manage-arrivals",
    icon: "pricetags-outline"
  },
  {
    name: "Manage Filter",
    routerLink: "/manage-filter",
    icon: "filter-outline"
  },
  {
    name: "Manage Service Provider",
    routerLink: "/manage-serviceprovider",
    icon: undefined,
    class: "surface",
    src: "../../assets/icon/surface.svg"
  },
  {
    name: "User Contacts",
    routerLink: "/user-contact",
    icon: 'list-outline'

  },
  {
    name: "Posts Reports",
    routerLink: "/post-report",
    icon: "alert-circle-outline"
  }, {
    name: 'CMS',
    routerLink: "/cms",
    icon: "alert-circle-outline"
  }
]
