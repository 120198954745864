import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AdminPassComponent } from './admin-pass/admin-pass.component';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    constructor(private modalController: ModalController) { }
    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        const modal = await this.modalController.create({
            component: AdminPassComponent,
            cssClass:"mymodule"
        });

        await modal.present();
        const data = await modal.onDidDismiss();
        return data.data.isAdmin;
    }


}
