import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { AdminGuard } from './provider/guard/admin.guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    // ...canActivate(redirectUnauthorizedToLogin),

  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'notification',
    // ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationPageModule),
  },
  {
    path: 'slider',
    ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AdminGuard],
    loadChildren: () => import('./slider/slider.module').then(m => m.SliderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    // ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'manage-filter',
    ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AdminGuard],
    loadChildren: () => import('./manage-filter/manage-filter.module').then(m => m.ManageFilterPageModule)
  },
  {
    path: 'manage-users',
    ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AdminGuard],

    loadChildren: () => import('./manage-users/manage-users.module').then(m => m.ManageUsersPageModule)
  },
  {
    path: 'manage-arrivals',
    ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AdminGuard],
    loadChildren: () => import('./manage-arrivals/manage-arrivals.module').then(m => m.ManageArrivalsPageModule)
  },
  {
    path: 'profile-view',
    ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () => import('./profile-view/profile-view.module').then(m => m.ProfileViewPageModule)
  },
  {
    path: 'manage-serviceprovider',
    ...canActivate(redirectUnauthorizedToLogin),
    canActivate: [AdminGuard],
    loadChildren: () => import('./manage-serviceprovider/manage-serviceprovider.module').then(m => m.ManageServiceproviderPageModule)
  },
  {
    path: 'post-report',
    ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () => import('./post-report/post-report.module').then(m => m.PostReportPageModule)
  },
  {
    path: 'user-contact',
    loadChildren: () => import('./user-contact/user-contact.module').then(m => m.UserContactPageModule)
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then( m => m.CmsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
