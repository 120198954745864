import { Injectable } from "@angular/core"
import { ToastController, AlertController, LoadingController } from "@ionic/angular"
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class MassageService {
    private loading
    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
        private loaderctrl: LoadingController
    ) {
        this.setLoading()
    }
    async setLoading() {
        this.loading = await this.loaderctrl.create({
            cssClass: 'my-custom-class',
            message: 'Please wait...',
            duration: 2000
        });
    }

    presentLoadin() {
        this.loading.present();
    }

    dismissLoader() {
        if (this.loading) {
            this.loading.dismiss()
        }
    }

    async DeleteAlert(callbakFN) {
        var alertconfirm
        const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: "Alert",
            subHeader: "Are you sure you want to delete",

            buttons: [
                {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                        alertconfirm = false
                    },
                },
                {
                    text: "Okay",
                    handler: () => {
                        alertconfirm = true
                        callbakFN()
                    },
                },
            ],
        })

        await alert.present()
        // if (alertconfirm != null) {
        // return alertconfirm
        // }
    }

    async presentToast(title, color?) {
        const toast = await this.toastController.create({
            message: title,
            duration: 2000,
            position: "top",
            color: (color ? color : ""),

        })
        toast.present()
    }
    async presentMassage(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
            position: "top"
        })
        toast.present()
    }
}
